
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'cm_search-by-text__search-page' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '21941' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                    '\n        for your ',
                    _createElement('b', { 'key': '22951' }, '\'', this.vehicleString, '\'.')
                ] : null, this.query ? [
                    ' for the entered VIN number ',
                    _createElement('b', { 'key': '24421' }, '\'', this.query, '\'.')
                ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '25781' }, '\n      No results were found for your\n      ', this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '26891' }, '\'', this.query, '\'.')
                ] : null, !this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '27801' }, '\'', this.search, '\'.')
                ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '2908'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3123'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3352'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '34980' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '35850' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '36680' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '34634' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [_createElement('aside', {
            'className': 'cm_side-panel' + (!window.document.body.classList.contains('page--category') ? ' page-sidebar category-sidebar' : ''),
            'key': '75350'
        }, _createElement('div', { 'className': 'sidebar-box' }, window.location.pathname === '/year-model-search/' ? [[this.SearchVehicleWidget_sidepanel(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry3(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry4(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3521'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3723'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7541'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7544'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'key': '2843'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__side-panel cm_SearchVehicleWidget_sidepanel' }, this.template === 'active' ? _createElement('div', {
                        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                        'key': '103'
                    }, _createElement('h3', {}, 'Search by Year, Make, Model, and Trim'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle
                    }, '\n                Search\n              '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle
                    }, '\n                Clear\n              ')))) : null, _createElement('p', { 'className': 'cm_vehicle-widget_not-listed' }, 'Vehicle not listed? ', _createElement('a', { 'href': '/contact-us/' }, 'Click Here')));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]] : null, window.location.pathname !== '/year-model-search/' ? [
            _createElement('h3', { 'key': '153821' }, 'Search by Year, Make, Model, and Trim'),
            _createElement('a', {
                'className': 'button',
                'href': '/year-model-search/',
                'key': '153823'
            }, 'YEAR/MAKE/MODEL/TRIM')
        ] : null), _createElement('div', { 'className': 'cm_side-panel__search-by-size sidebar-box' }, _createElement('h3', {}, 'Search by Size and other specifications'), _createElement('p', {}, 'Search by Size, Style, and more'), _createElement('a', {
            'className': 'button',
            'href': '/custom-search/'
        }, 'Custom Search')), _createElement('div', { 'className': 'cm_side-panel__search-by-part-number sidebar-box' }, _createElement('h3', {}, 'Search by Part Number'), _createElement('p', {}, 'Search using the OEM part number printed on your OE lift'), [this.PartNumberSearchBox(function () {
                return _createElement('div', { 'className': 'cm_search-box-root__part-number cm_search-box-root__side-panel cm_PartNumberSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [this.searchInput(function () {
                        return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Enter Part Number');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('span', { 'className': 'cm_search-box_clear-container' }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '531'
                }, '\n              ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n            ') : null)), _createElement('button', {
                    'data-cm-role': 'add-query',
                    'className': 'button'
                }, 'Search')));
            }, {
                widgetName: 'undefined',
                items: undefined
            })], _createElement('p', { 'className': 'sidebar-box-info' }, _createElement('small', {}, _createElement('b', {}, 'Example:'), ' F85-278-406A-11AA\n        ', _createElement('br', {}), _createElement('b', {}, 'Enter Above:'), ' F85278406A11AA\n      '))), _createElement('div', { 'className': 'sidebar-box sidebar-box-transparent' }, _createElement('h3', {}, _createElement('i', { 'className': 'fa fa-exclamation-circle' }), ' Please Note'), '\n    Items sold on our site are sold individually unless otherwise noted. Images are to illustrate front and\n    rear of supports, please purchase the quantity needed in your order. Thank you!', _createElement('br', {}), _createElement('br', {}), _createElement('h3', {}, 'Still can\'t find your lift support?'), _createElement('a', { 'href': '/contact-us/' }, 'Click here to contact us'), ' and we\'ll find it for you and please allow 24 business\n    hours for reply.\n  '))], [!this.isLoading && this.totalHits > 0 ? _createElement('main', {
            'id': 'itemsBlock',
            'className': 'page-content',
            'key': '180450'
        }, _createElement('div', { 'className': 'cm_search-by-text__search-header listing-header' }, _createElement('span', { 'className': 'search-header-query' }, '\n      Search Results\n      ', this.responseQuery ? [
            '\n        for "\n        ',
            _createElement('strong', { 'key': '182881' }, this.responseQuery),
            '\n        "\n      '
        ] : null), _createElement('span', { 'className': 'search-header-totalhits' }, '\n      Displaying ', _createElement('b', {}, this.pageFrom), ' - ', _createElement('b', {}, this.pageTo), ' of ', _createElement('b', {}, this.totalHits), ' results\n      ', this.responseQuery ? [
            ' for ',
            _createElement('strong', { 'key': '185871' }, this.responseQuery)
        ] : null)), [this.SearchResult(function () {
                function repeatItems1(items, itemsIndex) {
                    return [items(function () {
                            function repeatI1(i, iIndex) {
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'icon ' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'icon--ratingEmpty' : this.review_average - i < 0.75 ? 'icon--ratingHalf' : 'icon--ratingFull')
                                }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-star' })));
                            }
                            return _createElement('li', { 'className': 'product' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', { 'href': this.custom_url }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                                'className': 'card-image lazyautosizes lazyloaded',
                                'src': this.imageOrDefault(this.images_image_thumbnail1 || this.images_image_thumbnail2),
                                'alt': this.removeHTML(this.name),
                                'title': this.removeHTML(this.name),
                                'onError': this.onImageError
                            }))), _createElement('figcaption', { 'className': 'card-figcaption' }, _createElement('div', { 'className': 'card-figcaption-body' }, _createElement('a', {
                                'className': 'button button--small card-figcaption-button quickview',
                                'data-product-id': this.id
                            }, '\n            Quick view\n          '), _createElement('a', {
                                'href': this.custom_url,
                                'className': 'button button--small card-figcaption-button'
                            }, 'Learn More'), !this.has_options ? [_createElement('a', {
                                    'href': '/cart.php?action=add&product_id=' + this.id,
                                    'className': 'button button--small card-figcaption-button',
                                    'key': '7771'
                                }, '\n              ', this.availability === 'preorder' ? `Pre-Order Now` : `Add to Cart`, '\n            ')] : null))), _createElement('div', { 'className': 'card-body' }, _createElement('h4', { 'className': 'card-title' }, _createElement('a', { 'href': this.custom_url }, _createElement('span', { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', {
                                'className': 'card-text',
                                'data-test-info-type': 'price'
                            }, _createElement('div', { 'className': 'productView-rating' }, [_createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'productView-rating',
                                        'key': '14070'
                                    },
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1.bind(this)),
                                    _createElement('span', { 'className': 'productView-reviewLink' }, !this.review_count ? ['(No reviews yet)'] : null, this.review_count ? [
                                        '(',
                                        this.review_count,
                                        ' reviews)'
                                    ] : null)
                                ])]), _createElement('div', { 'className': 'price-section price-section--withoutTax' }, this.availability_description?.includes('Will ship on or before') ? _createElement('span', {
                                'className': 'nxt-availability-preorder',
                                'key': '2084'
                            }, '\n            ', this.availability_description, '\n          ') : null, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)), '\n          ', this.Quantity_Sold_As, '\n          ', this.availability_description?.includes('Express shipping') ? _createElement('div', {
                                'className': 'shipping-icon',
                                'key': '2401'
                            }, _createElement('img', {
                                'src': '/product_images/uploaded_images/free-shipping.png',
                                'title': 'Eligible for Express Shipping'
                            })) : null, this.availability_description?.includes('Usually ships within 24 hours') ? _createElement('div', {
                                'className': 'shipping-icon',
                                'key': '2647'
                            }, _createElement('img', {
                                'src': 'https://portal.liftsupportsdepot.com/images/mini/24icon.png',
                                'title': this.availability_description
                            })) : null, this.availability_description?.includes('Will ship on or before') ? _createElement('div', {
                                'className': 'shipping-icon',
                                'key': '2918'
                            }, _createElement('img', {
                                'src': 'https://portal.liftsupportsdepot.com/images/mini/calendaricon.png',
                                'title': this.availability_description
                            })) : null)))));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_search-by-text__search-results cm_SearchResult' }, [this.message(function () {
                        return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                                'href': 'mailto:support@convermax.com',
                                'className': 'cm_message-link'
                            }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                                'className': 'cm_message__incompatible',
                                'key': '4950'
                            }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                                'className': 'cm_message__universal',
                                'key': '7690'
                            }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                                'tabIndex': '0',
                                'className': 'cm_message-link',
                                'href': this.searchByVehicleUrl
                            }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                            'className': 'cmTemplate_badRequest',
                            'key': '1136'
                        }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver
                        }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                            'className': 'cmTemplate_corrected',
                            'key': '1554'
                        }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                            'className': 'cmTemplate_networkError',
                            'key': '1816'
                        }, 'Network error\n', this.error ? [
                            '(id: ',
                            this.error.userId,
                            ', timestamp: ',
                            this.error.timestamp,
                            ', instance: ',
                            this.error.instance,
                            ')'
                        ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                            'className': 'cmTemplate_nothing',
                            'key': '2108'
                        }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '21941' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                                '\n        for your ',
                                _createElement('b', { 'key': '22951' }, '\'', this.vehicleString, '\'.')
                            ] : null, this.query ? [
                                ' for the entered VIN number ',
                                _createElement('b', { 'key': '24421' }, '\'', this.query, '\'.')
                            ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '25781' }, '\n      No results were found for your\n      ', this.query ? [
                                ' query ',
                                _createElement('b', { 'key': '26891' }, '\'', this.query, '\'.')
                            ] : null, !this.query ? [
                                ' query ',
                                _createElement('b', { 'key': '27801' }, '\'', this.search, '\'.')
                            ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                            'className': 'cmTemplate_timeout',
                            'key': '2908'
                        }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.retry
                        }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                            'className': 'cmTemplate_partial',
                            'key': '3123'
                        }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                            'className': 'cmTemplate_unknown',
                            'key': '3352'
                        }, 'We failed to process your request', this.error ? [
                            '\n  (',
                            this.error.status ? [
                                _createElement('b', { 'key': '34980' }, 'status:'),
                                ' ',
                                this.error.status,
                                ', '
                            ] : null,
                            this.error.userId ? [
                                _createElement('b', { 'key': '35850' }, 'id:'),
                                ' ',
                                this.error.userId,
                                ', '
                            ] : null,
                            this.error.timestamp ? [
                                _createElement('b', { 'key': '36680' }, 'timestamp:'),
                                ' ',
                                this.error.timestamp,
                                ', '
                            ] : null,
                            _createElement('b', { 'key': '34634' }, 'instance:'),
                            ' ',
                            this.error.instance,
                            ')'
                        ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.retry
                        }, 'try your search again'), '.\n') : null);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement.apply(this, [
                    'ul',
                    { 'className': 'productGrid cmRepeater_items' },
                    _map(this.items, repeatItems1.bind(this))
                ]));
            }, {
                widgetName: 'results',
                items: undefined
            })], [this.pagination(function () {
                function repeatButtons1(buttons, buttonsIndex) {
                    return [buttons(function () {
                            return this.template === 'prev' ? _createElement('li', { 'className': 'pagination-item pagination-item--previous cmTemplate_prev' }, _createElement('a', { 'className': 'pagination-link' }, _createElement('i', {
                                'className': 'icon',
                                'aria-hidden': 'true'
                            }, _createElement('svg', {}, _createElement('use', {
                                'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                'xlinkHref': '#icon-chevron-left'
                            }))), '\n        Previous\n      ')) : this.template === 'next' ? _createElement('li', { 'className': 'pagination-item pagination-item--next cmTemplate_next' }, _createElement('a', { 'className': 'pagination-link' }, '\n        Next\n        ', _createElement('i', {
                                'className': 'icon',
                                'aria-hidden': 'true'
                            }, _createElement('svg', {}, _createElement('use', {
                                'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                'xlinkHref': '#icon-chevron-right'
                            }))))) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('a', {
                                'className': 'pagination-link',
                                'key': '121'
                            }, this.page) : null, !this.isActive ? _createElement('a', {
                                'className': 'pagination-link',
                                'key': '192'
                            }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_pagination' }, _createElement('div', { 'className': 'pagination nxt-address' }, _createElement.apply(this, [
                    'ul',
                    { 'className': 'pagination-list cmRepeater_buttons' },
                    _map(this.buttons, repeatButtons1.bind(this))
                ])));
            }, {
                widgetName: 'undefined',
                items: undefined
            })]) : null]);
}
        export const componentNames = ["cm:message","cm:filterInput","cm:SearchVehicleWidget_sidepanel","cm:searchInput","cm:PartNumberSearchBox","cm:message","cm:SearchResult","cm:pagination"]